import React, { Component } from 'react'
import "./Career.css"


class Career extends Component {
  render() {
    return (
      <div className='ContentFrame'>
        <h2>Career</h2>
        <div className='TextFrame'>
        <p>
          Hello. I’m Ruize (Ethan) Sheng.
        </p>
        <p>
          An enthusiastic software engineer with strong backend development skills in Java, Spring Boot, Node.js, Python, FastAPI and cloud technologies (AWS), and frontend development skills with React, Vue (JavaScript & TypeScript).
        </p>
        <p>
          Currently leading the development of a microservices-based tax filing system.
        </p>
        <p>
          Passionate about solving real-world problems with clean architecture, secure design, and efficient code.
        </p>
        <p>
          In my spare time, I’m also passionate about photography & stargazing, especially astrophotography, which sharpened my eye for design and made me proficient in Photoshop, Lightroom & Premiere—skills I find especially useful in front-end development.
        </p>
        <p>
        Technical skills:
        </p>
        <ul>
          <li>Languages: Java, Python, JavaScript/TypeScript </li>
          <li>Backend Technologies/Frameworks: Spring Boot, Node.js, FastAPI, Redis, RabbitMQ </li>
          <li>Databases: SQL DBs: PostgreSQL, MySQL, Oracle DB; NoSQL DBs: MongoDB, AWS DynamoDB, Redis </li>
          <li>Frontend Technologies/Frameworks: HTML5, CSS, React, Vue, React Native </li>
          <li>Developer Environments/Tools: Windows, Linux, AWS, Git, Visual Studio, Eclipse, VS Code, IntelliJ, PyCharm, Nginx</li>
        </ul>
        

        <p>
          Links to my projects (GitHub links):
        </p>
        <dl>
          <li>
            Personal Website (JavaScript, Node.js, React.js, REST API, MongoDB, Nginx)
          </li>
          <dd>
            <a href='https://github.com/AP1ruize/personal-website-frontend-reactjs'>
            frontend: React.js Project
            </a> 
          </dd>
          <dd>
            <a href='https://github.com/AP1ruize/personal-website-nodejs-api'>
            REST APIs: Express.js app
            </a>
          </dd>
          <li>
          <a href='https://github.com/AP1ruize/oracle_assig'>Library Management System (Java, HTML, CSS, JS, Oracle DB)</a>
          </li>
          <dd>
            <a>A preview of the web homepage of this project is shown below, to demonstrate the web design.</a>
          </dd>
          <li>
          <a href='https://github.com/AP1ruize/online_exam_system_j2ee'>Online Exam System (Java EE, MySQL, Eclipse)</a>
          </li>
          <li>
          <a href='https://github.com/AP1ruize/sentiment_classfication_of_movie_reviews_3rd_yrs_proj'>Sentiment Classification of Movie Reviews (Python, PyCharm)</a>
          </li>
        </dl>
        
        </div>
        
        <div className='TextFrame'>
          <p>The frame below is the preview of the web design of the Library Management System project</p>
          <p>Notice: This frame ONLY shows the dynamic responsive effort of the web page design. Because the preview isn't connected to the backend, functions of the system don't work in the preview. </p>
          <a href='/lms.html'>click here to show the preview in full screen mode</a>
          <div className='Content'>
          <p>
            <iframe align="center" width="90%" height="600" src="./lms.html"  frameborder="yes" border="2px" marginwidth="2px" marginheight="2px" scrolling="yes"></iframe>
          </p>
          </div>
          
        </div>
        
        
      </div>
      
    )
  }
}

export default  Career;